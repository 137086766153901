import { ACTIONS } from '@/modules/b2b/store/notification/actions';
import { setBadgeNumber } from '@/modules/shared/utils/badge';
import { useConfig, useGetStorageData } from '..';

export const useReadAllNotification = () => {
  const { store, storePath } = useConfig();
  const { selectedCompany, selectedUser } = useGetStorageData();
  const STORE_MODULE_PATH = `${storePath}/notification`;

  const onReadAllNotifications = async () => {
    const { id: customerId } = await selectedCompany.value;
    const { tenant } = await selectedUser.value;
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.MARK_ALL_AS_READ_NOTIFICATIONS}`);
    await setBadgeNumber(store, customerId, tenant.id);
  };

  return {
    onReadAllNotifications
  };
};

<template>
  <ion-grid class="ion-padding">
    <ion-row>
      <ion-col size="3">
        <td-image :image="sku?.image" :imagePath="IMAGE_PATH.PRODUCT"></td-image>
      </ion-col>
      <ion-col size="9" class="d-flex flex-column">
        <div class="d-flex flex-column">
          <ion-text class="font-style-button-small text-gray-900 mb-1">{{ sku.name }}</ion-text>
          <ion-text :class="['font-style-tab-bar text-white-000 mb-1 px-1', computedRef.halal[0]]">{{
            computedRef.halal[1]
          }}</ion-text>
          <ion-text class="font-style-tab-bar text-gray-700 mb-1">{{ sku.tenant_name }}</ion-text>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="d-flex flex-column">
          <ion-text class="font-style-card-subtitle text-secondaryy text-uppercase">{{
            $t('packaging_size')
          }}</ion-text>
          <ion-text class="font-style-title text-black-primary mt-1">{{
            computedRef.packagingSize
          }}</ion-text>
        </div>
      </ion-col>

      <ion-col>
        <div class="d-flex flex-column">
          <ion-text class="font-style-card-subtitle text-secondaryy text-uppercase">{{
            $t('weight')
          }}</ion-text>
          <ion-text class="font-style-title text-black-primary mt-1"
            >{{ sku.is_order_by_weight ? $t('order_by_weight') : computedRef.weight }}
          </ion-text>
        </div>
        <div class="d-flex flex-row align-items-center" v-if="sku.is_catch_weight">
          <ion-text class="fs-2 text-black-primary mt-1">{{ $t('catch_weight') }} </ion-text>
          <ion-img
            @click="showTooltip()"
            src="/assets/images/tooltip-question-icon.svg"
            alt="tooltip"
            style="width: 1.3rem"
          />
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="mt-2">
      <ion-col class="d-flex flex-column">
        <ion-text class="text-gray-700">{{ isB2b ? $t('regular_price') : $t('direct_price') }}</ion-text>
        <ion-text class="direct-price my-1">{{
          isLabeledByWeight ? computedRef.price_per_weight : computedRef.price
        }}</ion-text>
        <ion-text class="price-unit" v-if="!isLabeledByWeight">{{
          `(${computedRef.price_per_weight})`
        }}</ion-text>
      </ion-col>

      <ion-col v-if="isSalesAdmin" class="d-flex flex-column">
        <ion-text class="text-gray-700">{{ $t('quotation_limit_price') }}</ion-text>
        <ion-text class="direct-price my-1">{{
          isLabeledByWeight ? computedRef.price_per_weight_quote : computedRef.price_quote
        }}</ion-text>
        <ion-text class="price-unit" v-if="!isLabeledByWeight">{{
          `(${computedRef.price_per_weight_quote})`
        }}</ion-text>
        <!-- <ion-row v-if="userData.user_type_id === 6 || userData.user_type_id === 12">
          <ion-col size="1">
            <ion-icon class="info-icon" :icon="informationCircleOutline"></ion-icon>
          </ion-col>
          <ion-col>
            <ion-tex class="price-info">{{ $t('warning_info_price') }}</ion-tex></ion-col
          >
        </ion-row> -->
        <!-- <div v-else class="d-flex flex-column">
          <ion-text class="direct-price my-1">-</ion-text>
          <ion-text class="price-unit">-</ion-text>
        </div> -->
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-text v-if="computedRef.price_is_tax_inclusive" class="text-gray-700">
          {{ $t('price_shown_tax_inclusive') }}</ion-text
        >
        <ion-text v-else class="text-gray-700"> {{ $t('price_shown_before_tax') }}</ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button
          style="height: 2.2rem"
          @click="onRedirectProductDetail(sku.product_id)"
          expand="block"
          color="primary"
          fill="outline"
          class="text-capitalize"
          >{{ $t('view_product') }}</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { displayPrice } from '@/modules/b2b/services/libs/helper';
import { IMAGE_PATH } from '@/modules/sale/constants';
import { DEFAULT_WEIGHT_UOM, QUOTED_PRICED_BY, USER_TYPE_ID } from '@/modules/shared/constants';
import { getUserType, handleCheckHalal, handlePricePerWeight, useCheck } from '@/modules/shared/utils/';
import { useAlert } from '@/usecases/global';
import { priceFormatter } from '@/utils';
import { informationCircleOutline } from 'ionicons/icons';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
export default {
  name: 'ProductDetails',
  emits: ['closeModal'],
  props: {
    sku: {
      type: Object,
      default: () => ({})
    },
    user: {
      type: Object,
      default: () => ({})
    },
    currencySymbol: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const userData = ref(props.user);
    const router = useRouter();
    const { createAlert } = useAlert();
    const { checkWeightItem, combineTwoUnit, isItemKGAndNoneUnit } = useCheck();
    const { t } = useI18n();
    const prefixPath = () => getUserType(userData.value.user_type_id);
    const skuVariation = ref(
      JSON.parse(props.sku.specs, (_, value) => {
        return value;
      })
    );
    const isLabeledByWeight = ref(props.sku.is_order_by_weight);
    const isCalculateByWeight =
      props.sku.is_catch_weight ||
      props.sku.is_order_by_weight ||
      props.sku.priced_by === QUOTED_PRICED_BY.WEIGHT;
    const isB2b = [USER_TYPE_ID.BUYER, USER_TYPE_ID.SUB_BUYER].includes(props.user.user_type_id);
    const isSalesAdmin = [USER_TYPE_ID.ADMIN, USER_TYPE_ID.SALE, USER_TYPE_ID.SUPPLIER].includes(
      props.user.user_type_id
    );

    const computedRef = reactive({
      halal: computed(() => {
        return handleCheckHalal(props.sku.halal);
      }),
      packagingSize: computed(() => {
        if (props.sku) {
          const { unit_amount, uom_name, unit_per_item, unit_per_item_uom } = props.sku;
          if (isItemKGAndNoneUnit(uom_name, unit_per_item)) {
            return '-';
          }
          const itemsInProduct = checkWeightItem(unit_amount, uom_name);
          const unitsInItem = checkWeightItem(unit_per_item, unit_per_item_uom);
          return combineTwoUnit(itemsInProduct, unitsInItem);
        }
        return '';
      }),
      weight: computed(() => {
        return `${props.sku.weight} kg`;
      }),
      price: computed(() => {
        return priceFormatter(props.currencySymbol, displayPrice(props.sku.direct_price));
      }),
      price_per_weight: computed(() => {
        return handlePricePerWeight(
          props.currencySymbol,
          props.sku.direct_price,
          isCalculateByWeight ? props.sku.weight : props.sku.unit_amount,
          isCalculateByWeight ? DEFAULT_WEIGHT_UOM : props.sku.uom_name
        );
      }),
      price_quote: computed(() => {
        return isSalesAdmin
          ? priceFormatter(props.currencySymbol, displayPrice(props.sku.quotation_limit_price))
          : '-';
      }),
      price_per_weight_quote: computed(() => {
        return handlePricePerWeight(
          props.currencySymbol,
          props.sku.quotation_limit_price,
          isCalculateByWeight ? props.sku.weight : props.sku.unit_amount,
          isCalculateByWeight ? DEFAULT_WEIGHT_UOM : props.sku.uom_name
        );
      }),
      price_is_tax_inclusive: computed(() => {
        return props.sku.price_is_tax_inclusive;
      })
    });
    const onRedirectProductDetail = (productId) => {
      router.push({
        path: `/${prefixPath()}/main/product-detail/${productId}`
      });
      emit('closeModal');
    };

    const showTooltip = async () => {
      await createAlert(
        `${t('catch_weight')} ${t('orderB2b.item')}`,
        `${t('tooltip_message_start')} ${t('tooltip_message_second')} <mark class="tooltip-question"> ${t(
          'weight_size'
        )} </mark> ${t('and')} <mark class="tooltip-question"> ${t('price_normal')} </mark> ${t(
          'tooltip_message_third'
        )} ${t('tooltip_message_four')} ${t('tooltip_message_end')}`
      );
    };
    return {
      isB2b,
      isSalesAdmin,
      showTooltip,
      computedRef,
      onRedirectProductDetail,
      skuVariation,
      userData,
      informationCircleOutline,
      IMAGE_PATH,
      USER_TYPE_ID,
      isLabeledByWeight
    };
  }
};
</script>

<style lang="scss" scoped>
ion-grid:first-child {
  & > ion-row:nth-child(1) {
    & > ion-col:nth-child(2) {
      & > div:nth-child(1) {
        & > ion-text:nth-child(2) {
          align-self: flex-start;
          padding: 3px;
          border-radius: 8px;
        }
      }
    }
  }
}

.direct-price {
  font-size: 14px;
  color: black;
  font-weight: 900;
}

.price-unit {
  font-size: 11px;
  color: #039855;
}
.halal {
  background-color: #039855;
}
.non-halal {
  background-color: #f29727;
}

.info-icon {
  height: 12.5px;
  width: 12.5px;
  color: #f29727;
  margin-top: 2px;
}

.price-info {
  font-size: 11px;
  color: #f29727;
}
</style>
